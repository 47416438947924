<template>
  <div class="LoginBody">
    <div class="Login">
      <LoginTop middleTop="登录账号" :loShow="false" :reShow="true" />

      <LoginText
        label="账号"
        style="margin-top: 15px"
        placeholder="请输入账号"
        rule="^.{1,16}$"
        @inputChange="accountChange"
      />

      <LoginText
        label="密码"
        placeholder="请输入密码"
        type="password"
        rule="^.{2,16}$"
        @inputChange="passwordChange"
      />
      <LoginBtn btnText="登录" @loginSubmit="loginSubmit" />
    </div>
  </div>
</template>

<script>
import LoginTop from "@/components/common/LoginTop.vue";
import LoginText from "@/components/common/LoginText.vue";
import LoginBtn from "@/components/common/LoginBtn.vue";
import { Toast } from "vant";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  components: {
    LoginTop,
    LoginText,
    LoginBtn,
  },

  methods: {
    accountChange(content) {
      this.username = content;
    },
    passwordChange(content) {
      this.password = content;
    },
    loginSubmit() {
      const _this = this;
      console.log(this.password);
      this.$axios
        .post("/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          const jwt = response.headers["authorization"];
          const userInfo = response.data.data;
          _this.$store.commit("SET_TOKEN", jwt);
          _this.$store.commit("SET_USERINFO", userInfo);
          Toast("登录成功！( •̀ ω •́ )✧");
          _this.$router.push("/main");
        });
    },
  },
};
</script>

<style scoped>
.Login {
  /* height: 250px; */
  width: 100%;
  background-color: white;
  /* height: 100vh; */
}

.LoginBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
}
</style>